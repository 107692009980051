import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider Component to wrap your app
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [mainProfile, setMainProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    const isTokenExpired = (token) => {
        try {
            const decodedToken = jwtDecode(token); // Decode the JWT
            const currentTime = Date.now() / 1000; // Get current time in seconds
            return decodedToken.exp < currentTime; // Check if the token is expired
        } catch (error) {
            console.error("Error decoding token:", error);
            return true; // Treat invalid tokens as expired
        }
    };

    // Fetch user and mainProfile data sequentially
    const fetchData = async (storedToken = null) => {
        try {
            // Fetch user data
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                headers: {
                    Authorization: `Bearer ${storedToken ?? token}`,
                },
            });
            
            setUser(userResponse.data);

            // Fetch mainProfile data
            const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/profile/myprofile`, {
                headers: {
                    Authorization: `Bearer ${storedToken ?? token}`,
                },
            });

            // console.log('fetch profile');
            // console.log('profileResponse.data');
            setMainProfile(profileResponse.data);

        } catch (error) {
            console.error('Error fetching profile data:', error);
            logout(); // Log out if fetching fails
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    };

    // Load the JWT from localStorage on app startup
    useEffect(() => {
        const storedToken = localStorage.getItem('los-jwt');
        if (storedToken) {
            if (isTokenExpired(storedToken)) {
                console.warn("Token is expired. Logging out...");
                logout(); // Logout if the token is expired
                setLoading(false);
                return;
            }

            setToken(storedToken);
            setIsAuthenticated(true);
            fetchData(storedToken);
        } else {
            setLoading(false); // No token, stop loading
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('los-jwt');
        setToken(null);
        setIsAuthenticated(false);
        setUser(null); 
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, token, user, mainProfile, loading, setToken, logout, fetchData }}>
            {children}
        </AuthContext.Provider>
    );
};

// Export the AuthContext for use in components
export default AuthContext;
