import React, { useState, useEffect, useContext } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Main.css';
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../modals/LoginModal';

const OtherPage = () => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [error, setError] = useState(null);
    const { isAuthenticated, token, loading, mainProfile } = useContext(AuthContext);
    const navigate = useNavigate();

    // Fetch partner data
    const fetchPartnerData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/partners`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch partner data');
            }

            const data = await response.json();
            setPartners(data);
        } catch (err) {
            setError(err.message);
            console.error('Error fetching partner data:', err);
        }
    };

    // Handle page initialization
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/'); // Redirect if not authenticated
        }

        if (mainProfile) {
            fetchPartnerData(); // Fetch data if `mainProfile` is available
        }
    }, [isAuthenticated, mainProfile, navigate]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal} />

            <div className="page-content-container">
                <h1 className="page-title">ดูดวงให้คนอื่น</h1>

                {loading && <p>Loading...</p>} {/* Show loading message */}
                {error && <p className="text-danger">Error: {error}</p>} {/* Show error message */}

                {!loading && !error && partners.length > 0 && (
                    <div className="form-container mb-3">
                        <label className="form-label">Select a Partner</label>
                        <select
                            className="form-select"
                            style={{width: "100%"}}
                            value={selectedPartner || ''}
                            onChange={(e) => setSelectedPartner(e.target.value)}
                            required
                        >
                            <option value="" disabled>
                                Choose a partner
                            </option>
                            {partners.map((partner) => (
                                <option key={partner.id} value={partner.id}>
                                    {partner.firstName} {partner.lastName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                {!loading && !error && partners.length === 0 && (
                    <p>No profile available at this time.</p> // Handle empty partner list
                )}

                {selectedPartner != null && <button className="btn btn-success mt-4" onClick={() => navigate('/other/'+selectedPartner)}>ดูดวงให้คนอื่น</button>}

                <button className="btn btn-danger mt-4" onClick={() => navigate('/other/addprofile')}>เพิ่มข้อมูล</button>
            </div>

            <FooterLayout />
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    );
};

export default OtherPage;
