import axios from 'axios';
import React from 'react';
import AuthContext from './AuthContext'; // Ensure correct path to AuthContext

const useAxios = () => {
    const { token } = React.useContext(AuthContext); // Get the token from AuthContext

    // Create an Axios instance
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL, // Replace with your backend API base URL
    });

    // Add an interceptor to include the Authorization header in requests
    axiosInstance.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            // Handle request error
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxios;