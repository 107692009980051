import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import TrialVersionMainPage from './pages/TrialVersionMain';
import MainPage from './pages/Main';
import LoginSuccess from './pages/LoginSuccess';
import AddOtherProfilePage from './pages/AddOtherProfile';
import 'bootstrap-icons/font/bootstrap-icons.css';

import ProfilePage from './pages/Profile';
import NewsPage from './pages/News';
import PromotionPage from './pages/Promotion';
import PredictPage from './pages/Predict';
import PremiumPage from './pages/Premium';
import AppointmentPage from './pages/Appointment';
import LearnPage from './pages/Learn';
import OtherPage from './pages/Other';
import AddMainProfile from './pages/AddMainProfile';
import OtherPredictPage from './pages/OtherPredict';
import ProductPage from './pages/Product';

const router = createBrowserRouter([
  { path: "/", element: <MainPage /> },
  { path: "/trial/:id", element: <TrialVersionMainPage /> },
  { path: "/login-success", element: <LoginSuccess /> },
  { path: "/other/addprofile", element: <AddOtherProfilePage /> },
  { path: "/addmainprofile", element: <AddMainProfile /> },

  { path: "/profile", element: <ProfilePage /> },
  { path: "/news", element: <NewsPage /> },
  { path: "/promotion", element: <PromotionPage /> },
  { path: "/product", element: <ProductPage /> },

  { path: "/predict", element: <PredictPage /> },
  { path: "/other/:id", element: <OtherPredictPage /> },
  { path: "/premium", element: <PremiumPage /> },

  { path: "/appointment", element: <AppointmentPage /> },
  { path: "/learn", element: <LearnPage /> },

  { path: "/other", element: <OtherPage /> },
], {
  future: {
    v7_startTransition: true,             // Enables React.startTransition in v7
    v7_skipActionErrorRevalidation: true, // Changes revalidation behavior after 4xx/5xx `action` responses
    v7_partialHydration: true,            // Updates hydration behavior for `RouterProvider`
    v7_normalizeFormMethod: true,         // Normalizes casing of `formMethod` fields
    v7_fetcherPersist: true,              // Changes fetcher persistence behavior
    v7_relativeSplatPath: true            // Updates relative route resolution within Splat routes
  },
});

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
