import React, { useContext } from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import { useTranslation } from 'react-i18next';

const FooterLayout = ({ onLoginClick }) => {

  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleHomeClick = () => {
    navigate('/');
  }

  const handleLearnClick = () => {
    navigate('/learn');
  }

  const handlePredictClick = () => {
    if (isAuthenticated) {
      navigate('/predict');
    } else {
      onLoginClick();
    }
  }

  const handleProductClick = () => {
    if (isAuthenticated) {
      navigate('/product');
    } else {
      onLoginClick();
    }
  }

  const handleProfileClick = () => {
    if (isAuthenticated) {
      navigate('/profile');
    } else {
      onLoginClick();
    }
  }

  return (
    <div className="footer-container">

      <div className="footer-button1" onClick={handleHomeClick}>
        <img src="/home.png" alt="home"></img>
        <p>{t('home_menu')}</p>
      </div>

      <div className="footer-button2" onClick={handleLearnClick}>
        <img src="/book.png" alt="book"></img>
        <p>{t('learn_menu')}</p>
      </div>
      
      <div className="home-button-wrapper" onClick={handlePredictClick}>
        <img src="/predict.png" alt="predict"></img>
      </div>
      

      <div className="footer-button4" onClick={handleProductClick}>
        <img src="/market.png" alt="partner"></img>
        <p>{t('product_menu')}</p>
      </div>
      
      <div className="footer-button5" onClick={handleProfileClick}>
        <img src="/member.png" alt="profile"></img>
        <p>{t('profile_menu')}</p>
      </div>
      
      <div className="footer-background"></div>
    </div>
  );
};

export default FooterLayout;