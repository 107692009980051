import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate } from 'react-router-dom';
import "./Main.css";
import AuthContext from '../AuthContext';
import useAxios from '../UseAxios';
import { useTranslation } from 'react-i18next';
import LoginModal from '../modals/LoginModal';
import FooterWithCopyright from '../layouts/FooterWithCopyright';

const MainPage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);
    const navigate = useNavigate();
    const { isAuthenticated, user, mainProfile, loading } = useContext(AuthContext);
    const { t } = useTranslation();
    const axiosInstance = useAxios();

    const handleSlide1 = () => {
        if(isAuthenticated){
            navigate('/predict');
        }
        else{
            showLoginModal();
        }
    }

    const handleSlide2 = () => {
        navigate('/learn');
    }

    const handleSlide3 = () => {
        navigate('/appointment');
    }

    const predictClick = () => {
        if(!isAuthenticated){
            showLoginModal();
        }
        else{
            navigate('/predict');
        }
    }

    useEffect(() => {
        if(!loading && isAuthenticated && !mainProfile){
            navigate('/addmainprofile');
        }
    }, [loading, mainProfile, navigate]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="main-content-container">
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    interval={3000}
                    stopOnHover
                    dynamicHeight
                >
                    <div onClick={handleSlide1}>
                        <img src="/slide1.png" alt="Slide 1" style={{ background: 'none' }} />
                    </div>
                    
                    <div onClick={handleSlide2}>
                        <img src="/slide2.png" alt="Slide 2" style={{ background: 'none' }}/>
                    </div>

                    <div onClick={handleSlide3}>
                        <img src="/slide3.png" alt="Slide 3" style={{ background: 'none' }}/>
                    </div>
                </Carousel>
            </div>

            <h1 className="top-content-text">
                ติดตามข่าวสารกับเรา
            </h1>

            <div className="top-content-container">

                <div className="top-content">
                    <img src="/news.png" alt="news" onClick={() => navigate('/news')}/>
                    <p>{t('news_menu')}</p>
                </div>

                <div className="top-content">
                    <img src="/promotion.png" alt="promotion" onClick={() => navigate('/promotion')}/>
                    <p>{t('promotion_menu')}</p>
                </div>
            </div>

            <h1 className="bottom-content-title">{t('title_one')}</h1>

            <div className="middle-content-container">
                <div className="middle-content" onClick={predictClick}>
                    <img src="/normal-predict.png" alt="normal-predict"/>
                </div>

                <div className="middle-content" onClick={() => navigate('/premium')}>
                    <img src="/premium-predict.png" alt="premium-predict"/>
                </div>
            </div>
            
            <h1 className="bottom-content-title">{t('title_two')}</h1>

            <div className="bottom-content-container">
                <div className="bottom-content" onClick={() => navigate('/appointment')}>
                    <img src="/appointment.png" alt="appointment"/>
                </div>

                <div className="bottom-content" onClick={() => navigate('/learn')}>
                    <img src="/study.png" alt="study"/>
                </div>
            </div>

            <FooterLayout onLoginClick={showLoginModal}/>

            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    
    );
};

export default MainPage;
