import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const InternalExternalDescription = ({ visible, onClose, internalData, externalData }) => {
  const { t } = useTranslation();

  if (!internalData) return null; // Check if data is available

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('internal_external_self_description')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{t('number')} : {internalData.number}</h1>
        <p><strong>{t('internal_self_description')}:</strong> {internalData.meaning}</p>
        <h1>{t('number')} : {externalData.number}</h1>
        <p><strong>{t('external_self_description')}:</strong> {externalData.meaning}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InternalExternalDescription;