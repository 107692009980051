import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import LoginModal from '../modals/LoginModal';
import { FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import AuthContext from '../AuthContext';

const AddMainProfile = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [country, setCountry] = useState('Thailand');
    const navigate = useNavigate();

    const { token, isAuthenticated, loading, mainProfile, fetchData } = useContext(AuthContext);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
    
        if (!birthdate) {
            alert("Please select a birthdate.");
            return;
        }
    
        // Extract birth date components
        const birthDay = birthdate.getDate();
        const birthMonth = birthdate.getMonth() + 1; // Months are zero-based
        const birthYear = birthdate.getFullYear();
    
        const data = {
            firstName: firstname,
            lastName: lastname,
            birthDate: birthDay,
            birthMonth: birthMonth,
            birthYear: birthYear
        };
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL+'/user/addmainprofile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                await fetchData();
                navigate(`/`);
            } else {
                console.error('Failed to create profile:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating profile:', error);
        }
    };

    const countryOptions = [
        'Thailand',
        'United States',
        'United Kingdom',
        'Japan',
        'Germany',
        'France',
        'Australia',
        'Canada',
        'India',
        'China',
    ];

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/');
        }

        if(!loading){
            // console.log(mainProfile)
        }

        if(!loading && mainProfile){
            navigate('/');
        }
    }, [loading]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">
                <h1 className="page-text">โปรดเพิ่มข้อมูลส่วนตัวก่อนเริ่มใช้งาน</h1>

                <div className="form-container mt-3">
                    <form onSubmit={handleFormSubmit} className="border p-4 rounded shadow-sm bg-light">
                        <div className="mb-3">
                            <label className="form-label">ชื่อ ภาษาอังกฤษ</label>
                            <input
                                type="text"
                                className="form-control"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                placeholder="Enter your firstname"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">นามสกุล ภาษาอังกฤษ</label>
                            <input
                                type="text"
                                className="form-control"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Enter your lastname"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">วัน/เดือน/ปี เกิด</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                <FaCalendarAlt />
                                </span>
                                <DatePicker
                                selected={birthdate}
                                onChange={(date) => setBirthdate(date)}
                                className="form-control"
                                placeholderText="Select your birthdate"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Country</label>
                            <select
                                className="form-select"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            >
                                {countryOptions.map((country) => (
                                <option key={country} value={country}>
                                    {country}
                                </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary w-100">
                        Calculate
                        </button>
                    </form>
                </div>
            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    );
};

export default AddMainProfile;
