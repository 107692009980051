import React, { useState } from 'react';
import './LoginModal.css';
import { useTranslation } from 'react-i18next';

const LoginModal = ({ visible, onClose }) => {

  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const LINE_AUTH_URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006550532&redirect_uri=${encodeURIComponent("https://api.lightofsoul.in.th/line/callback")}&state=UNIQUE_STATE_STRING&scope=openid%20profile%20email`;

  const handleLogin = () => {
      window.location.href = LINE_AUTH_URL;
  };

  if (!visible) return null;

  return (
  <>
    <div className="modal-overlay"/>

      <div className="login-modal-container">

        <button className="login-button-close" onClick={onClose}>&times;</button>

        <div className="login-modal-content">

            <h1>{t('please_login_text_one')}</h1>
            <h1>{t('please_login_text_two')}</h1>
            <br/><br/>
            {errorMessage && <p className="login-error-message">{errorMessage}</p>}

            <img src="/btn_login_base.png" alt="line-login" onClick={handleLogin}></img>

        </div>
    </div>
  </>
  );
};

export default LoginModal;
