import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HiddenAndMissingDescription = ({ visible, onClose, hiddenData, missingData }) => {
  const { t } = useTranslation();

  if (!hiddenData) return null; // Check if data is available

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('hidden_missing_number_description')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{t('hidden_number')} : {hiddenData.number}</h3>
        <p>{hiddenData.meaning}</p>

        <h3>{t('missing_number')}:</h3>

        <ul>
          {missingData.map((missingNumber, index) => (
            <li key={index}><strong>{missingNumber.number}</strong> - {missingNumber.meaning}</li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HiddenAndMissingDescription;