import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import AuthContext from '../AuthContext';
import { useTranslation } from 'react-i18next';


const HeaderLayout = ( { onLoginClick } ) => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [ isLanguageOpen, setIsLanguageOpen ] = useState(false);
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleDropdown = () => setIsLanguageOpen( !isLanguageOpen );

  const handleHomeClick = () => navigate('/');

  const handleLogoutClick = () => {
    if (isAuthenticated) {
      logout();
      navigate('/');
    }
  };

  const handleProfileClick = () => {
    if (isAuthenticated) {
      navigate('/profile');
    } else {
      onLoginClick();
    }
  }

  const handleLearnClick = () => {
    navigate('/learn');
  }

  const handleProductClick = () => {
    if (isAuthenticated) {
      navigate('/product');
    } else {
      onLoginClick();
    }
  }

  const toggleLanguage = () => {
    if(currentLanguage == 'en'){
      i18n.changeLanguage('th');
    }
    else{
      i18n.changeLanguage('en');
    }

    toggleDropdown();
  };


  return (
    <div className="header-with-menu">
      <div className="nav-container">
        <div className="navbar">
          <div className="nav-language-dropdown">
            <div className="dropdown-selected" onClick={toggleDropdown}>
              <img
                src={currentLanguage === 'th' ? '/thai_icon.png' : '/english_icon.png'}
                alt={currentLanguage === 'th' ? 'Thai' : 'English'}
                className="language-icon"
              />
            </div>

            {isLanguageOpen && (
              <div className="custom-dropdown">
                <li onClick={toggleDropdown}>
                  <img src={currentLanguage === 'th' ? '/thai_icon.png' : '/english_icon.png'} alt={currentLanguage === 'th' ? 'Thai' : 'English'} className="language-icon"/>
                </li>
                <li onClick={toggleLanguage}>
                  <img src={currentLanguage === 'th' ? '/english_icon.png' : '/thai_icon.png'} alt={currentLanguage === 'th' ? 'English' : 'Thai'} className="language-icon"/>
                </li>
              </div>)}
          </div>

          {/* <div className="nav-profile-logo">
            <i className="bi bi-person-circle profile-logo" onClick={handleProfileClick}></i>
          </div> */}

          <div className="header-logo-flex" onClick={handleHomeClick}>
            <img
              className="header-logo"
              src="/logo.png"
              alt="Logo"
            />
            <h1>Light of Soul</h1>
          </div>

          <div className="desktop-nav">

            <p className="desktop-nav-link" onClick={() => navigate('/news')}>
              {t('news_menu')}
            </p>

            <p className="desktop-nav-link" onClick={() => navigate('/promotion')}>
              {t('promotion_menu')}
            </p>

            <p className="desktop-nav-link" onClick={handleLearnClick}>
              {t('learn_menu')}
            </p>

            <p className="desktop-nav-link" onClick={handleProductClick}>
              {t('product_menu')}
            </p>

            <p className="desktop-nav-link" onClick={() => navigate('/premium')}>
              {t('premium_menu')}
            </p>

            <p className="desktop-nav-link" onClick={() => navigate('/appointment')}>
              {t('appointment_menu')}
            </p>

          </div>

          {
            isAuthenticated ? 
            <div className="header-logout-section">
              <img className="header-profile-button" src="/member.png" alt="profile" onClick={handleProfileClick}></img>
              <div className="nav-logout-button" onClick={handleLogoutClick}>
                <img src="/logout_icon.png" alt="logout"/>
              </div>
            </div>
            
            : 
            <button className="nav-login-button" onClick={onLoginClick}>
              LOG IN
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default HeaderLayout;
