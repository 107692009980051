import React, { useContext } from 'react';
import './Footer.css';

const FooterWithCopyright = () => {


  return (
    <div className="footer-copyright">
        <p>Copyright © 2024 - Light of Soul</p>
    </div>
  );
};

export default FooterWithCopyright;