import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../modals/LoginModal';
import Triangle from '../components/Triangle';

const ProfilePage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);
    const navigate = useNavigate();
    const { isAuthenticated, loading, mainProfile } = useContext(AuthContext);

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/');
        }
    }, [isAuthenticated]);


    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>
            {!loading && 
            <div className="page-content-container">
                
                <h1 className="page-title">Profile Page</h1>

                <Triangle data={mainProfile.birthDateAnnoDominiBirthBase}/>

                <h1>{mainProfile.firstName} {mainProfile.lastName}</h1>
                <h1>เกิด {mainProfile.birthDate}/{mainProfile.birthMonth}/{mainProfile.birthYear}</h1>
                <h1>Premium Status : Trial</h1>
            </div>
            }
            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    
    );
};

export default ProfilePage;
